/*Header */
/*scroll header*/
$(window).scroll(function () {
	if ($(document).scrollTop() > 80) {
		$(".header_wrapper").addClass("scrolled");
	} else {
		$(".header_wrapper").removeClass("scrolled");
	}
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function (event) {
	var st = $(this).scrollTop();
	if (st > lastScrollTop) {
		// downscroll code
		$(".header_wrapper").addClass("down");
	} else {
		// upscroll code
		$(".header_wrapper").removeClass("down");
	}
	lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on("click", function () {
	return false;
});

/*Open Menu*/
$(".menu_open button").on("click", function () {
	if ($(this).hasClass("is-active")) {
		$("body").css("overflow-y", "scroll");
		$(this).removeClass("is-active");
		$(".headermenu").removeClass("is-active");
		$(".header_wrapper").removeClass("openmenu");
		$(".menu li").removeClass("aos-animate");
	} else {
		$(this).addClass("is-active");
		jQuery("body").css("overflow-y", "hidden");
		$(".headermenu").addClass("is-active");
		$(".header_wrapper").addClass("openmenu");
		setTimeout(function () {
			$(".menu li").each(function (index) {
				// $(this).addClass('aos-animate');
				// $(this).addClass('aos-init');
			});
		}, 1000);
	}
});

$(document).ready(function () {
	getMaxLiWidth();
	function getMaxLiWidth() {
		var maxWidth = 0;
		$(".sub-menu li a").each(function (i) {
			if (this.offsetWidth > maxWidth) maxWidth = this.offsetWidth;
		});
		console.log(maxWidth);
		maxWidth = 2 * maxWidth;
		$(".headermenu div ul.sub-menu").css("max-width", maxWidth + "px");
		//return maxWidth;
	}
});

/*Menu Animation*/
// $( document ).ready(function() {
//   $( ".menu li" ).each(function( index ) {
//       $(this).attr('data-aos','fade-up');
//       $(this).attr('data-aos-duration','600');
//       $(this).addClass('aos-init');
//       setTimeout(function() {$( ".menu li" ).removeClass('aos-animate');  }, 300);
//       if(index==0){
//           $(this).attr('data-aos-delay',100);
//       }else{
//         $(this).attr('data-aos-delay',index*150);
//       }
//   });
// });

/*Menu open submenu*/
// var submenu_height;
// $( document ).ready(function() {
//   $( ".menu li" ).each(function( index ) {
//     if($(this).hasClass('menu-item-has-children')){
//           submenu_height=$(this).find('.sub-menu').height();
//           $('.sub-menu').css('height','0px');
//     }
//   });
//
//   $( ".menu li" ).on("mouseover", function () {
//     //stuff to do on mouseover
//     if($(this).hasClass('menu-item-has-children')){
//       $(this).find('.sub-menu').css('height',submenu_height+'px');
//       $(this).find('.sub-menu').css('opacity','1');
//     }
//     // else{
//     //   $('.sub-menu').css('height','0px');
//     //   $('.sub-menu').css('opacity','0');
//     // }
//   });
//
// });

// import Swiper, { Navigation } from 'swiper';
$(document).ready(function () {
	/*Gallery*/
	const gallery_swi = new Swiper(".gallery_swipe", {
		slidesPerView: "1",
		centeredSlides: false,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});

	/*Suites*/
	const suites_swi = new Swiper(".suite_swipe", {
		slidesPerView: "1",
		centeredSlides: false,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});

	/*Villas*/
	const suites_swi_villas = new Swiper(".villa_swipe", {
		slidesPerView: "1",
		centeredSlides: false,
		navigation: {
			nextEl: ".swiper-button-next-villa",
			prevEl: ".swiper-button-prev-villa",
		},
	});
});

/*Homepage Custom Tab*/
$(".custom_tab a").on("click", function () {
	var mytab = $(this).attr("data-title");
	if (mytab == "suites") {
		$(".suite_swipe").addClass("active");
		$(".villa_swipe").removeClass("active");
	} else if (mytab == "villa") {
		$(".villa_swipe").addClass("active");
		$(".suite_swipe").removeClass("active");
	} else {
	}
	return false;
});

/*Homepage Location Map Animation*/
if ($("body").hasClass("home")) {
	var targetOffset = $("#location").offset().top - $(".header_wrapper").height();
	var w = $(window).scroll(function () {
		if (w.scrollTop() > targetOffset) {
			$(".path").addClass("animate");
		} else {
		}
	});

	var targetOffset_par = $("#concierge").offset().top - $(".header_wrapper").height();
	var w_par = $(window).scroll(function () {
		if (w_par.scrollTop() > targetOffset_par) {
			scrollRotate();
		} else {
		}
	});
}

function scrollRotate() {
	let image = document.getElementById("animation_image");
	// image.style.transform = "translateY( calc( 50% - (" + window.pageYOffset/15 + "px)))";
}
/*Contact Map Animation*/
$(document).ready(function () {
	if ($("body").hasClass("page-template-tmpl_contact")) {
		setTimeout(function () {
			$(".path").addClass("animate");
		}, 300);
	}
});
/*Homepage read more*/
$(".readless a").on("click", function () {
	$(".reamore").addClass("active");
	$(".reamore").removeClass("disapled");
	$(".readless").removeClass("active");
	$(".readless").addClass("disapled");
	return false;
});
$(".reamore a").on("click", function () {
	$(".readless").addClass("active");
	$(".readless").removeClass("disapled");
	$(".reamore").removeClass("active");
	$(".reamore").addClass("disapled");
	return false;
});

$(document).ready(function () {
	/*Swipe Js Galleries*/
	const swiper_gall = new Swiper(".gallery_page", {
		slidesPerView: "auto",
		spaceBetween: 20,
		centeredSlides: false,
		pagination: {
			el: ".swiper-pagination",
			type: "fraction",
			formatFractionCurrent: function (number) {
				return ("0" + number).slice(-2);
			},
			formatFractionTotal: function (number) {
				return ("0" + number).slice(-2);
			},
			renderFraction: function (currentClass, totalClass) {
				return (
					'<span class="' + currentClass + '"></span>' + " / " + '<span class="' + totalClass + '"></span>'
				);
			},
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
});

/*Choose gallery*/
$(".selected p").on("click", function () {
	if ($(this).parent().parent().find(".choose_gallery").hasClass("open")) {
		$(this).parent().parent().find(".choose_gallery").removeClass("open");
	} else {
		$(this).parent().parent().find(".choose_gallery").addClass("open");
	}
});

$(".choose_gallery li").on("click", function () {
	var selected = $(this).attr("data-title");
	$(".selected p").text(selected);
	$(".choose_gallery").removeClass("open");

	$(".galleries_all .gallery_page").each(function () {
		var selected_gallery = $(this).attr("data-gal-title");
		if (selected == selected_gallery) {
			$(".gallery_page").removeClass("showme");
			$(this).addClass("showme");
		}
	});
});

jQuery(window).on("load", function () {
	let textarea = document.getElementById("g-recaptcha-response-100000");
	textarea.setAttribute("aria-hidden", "true");
	textarea.setAttribute("aria-label", "do not use");
	textarea.setAttribute("aria-readonly", "true");
});

document.addEventListener("DOMContentLoaded", function () {
	const shadowHost = document.querySelector("#rp-allinone"); // The main wrapper
	if (shadowHost) {
		const shadowRoot = shadowHost.shadowRoot; // Access the shadow DOM
		if (shadowRoot) {
			const widgetMaximized = shadowRoot.querySelector(".SuperWidgetMaximized-root"); // Find the element inside Shadow DOM
			const widgetMinimized = shadowRoot.querySelector(".SuperWidgetMinimized-root"); // Find the element inside Shadow DOM
			if (widget) {
				widgetMaximized.style.position = "fixed";
				widgetMaximized.style.bottom = "200px";
				widgetMaximized.style.right = "40px";
				widgetMinimized.style.position = "fixed";
				widgetMinimized.style.bottom = "200px";
				widgetMinimized.style.right = "40px";
			}
		}
	}
});
